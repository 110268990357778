<template>
	<div class="page">
		<div class="title" v-if="info.title">
			{{info.title}}
			<p class="yellowLine"></p>
		</div>
		<div class="content" v-html="info.describe">
			<!-- <p class="tit">BMW二手车回家标准服务</p>
			<p>作为一家享誉全球的汽车品牌，BMW始终坚持践行“以客户为中心”的理念，致力为车主提供贴心礼遇。</p>
			<p>而BMW二手车回家标准服务就是BMW专为二手车主所推出的一项贴心服务礼遇，旨在让二手车主同样拥有完整BMW体验。BMW二手车主的回归礼遇共有六重，包括“免费车辆健康检查”一次、“免费原厂机油保养”一次、“召回和技术升级服务”、“免费注册My BMW APP”、“互联驾驶服务基础合同免费延期”和“车辆使用培训”。“回家”的BMW二手车主，在享受六重回归礼遇的同时，还将开启BMW完整体验。</p>
			<div>
				<p>一、开启价值服务体验，包括享受BMW长悦保养回馈计划，专业养护体验与更低保养费用并驾齐驱；</p>
				<p>二、开启便捷服务体验，包括BMW免费上门取送车服务，足不出户即可送爱车出门保养；</p>
				<p>三、开启数字化服务体验，包括BMW微信服务号便捷服务等，服务预约更便捷；</p>
				<p> 四、开启关爱服务体验，包括BMW终身免费事故救援服务，BMW 24小时待命；</p>
				<p>五、开启质量服务体验，包括只提供原装BMW配件的BMW售后服务。</p>
			</div>
			<p class="tit">MINI二手车回家标准服务</p>
			<p>全国范围均有MINI授权经销商上线。</p>
			<p>无论你的二手MINI来自哪里，进店即享四重礼遇：</p>
			<ol>
				<li>一次免费机油保养，价值约900 -1,100元。</li>
				<li>一次免费车辆健康检查，由宝马认证技师操刀。</li>
				<li>未完成的召回和技术升级，一次性补上。</li>
				<li>一份免费的车辆使用指南。</li>
			</ol> -->
		</div>
		<swiper :options="swiperOption" ref="mySwiper">
			<swiper-slide class="swiper-slide" v-for="(item,index) in bannerList" :key="index">
				<img :src="item.image" mode="widthFit" class="banner" />
			</swiper-slide>
			<div class="swiper-pagination" slot="pagination"></div>
		</swiper>
		<video :src="info.video" v-if="info.video" controls></video>
	</div>
</template>

<script>
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper/src/index.js";
	import 'swiper/dist/css/swiper.css';
	import mixinLang from '../components/mixin.js'
	export default {
		mixins: [mixinLang],
		components: {
			swiper,
			swiperSlide
		},
		data() {
			return {
				bannerList: [],
				info: {},
				// 轮播配置
				swiperOption: {
					slidesPerView: 1,
					loop: true,
					autoplay: {
						delay: 3000,
						stopOnLastSlide: false,
						disableOnInteraction: false
					},
					// 显示分页
					pagination: {
						el: ".swiper-pagination",
						clickable: true //允许分页点击跳转
					}
				}
			}
		},
		methods: {
			getInfo() {
				this.$api.getConfigure({
					type: 7
				}).then(res => {
					console.log(res)
					this.info = res.data
				})
				this.$api.getBannerList({
					cate_no: '324gxfg12'
				}).then(res => {
					this.bannerList = res.banner_list
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		padding-top: 171px;
		position: relative;
		line-height: 46px;
	}

	.yellowLine {
		margin: 50px 0 40px;
	}

	.content {
		margin: 0 60px;
		background-color: #EEEEEE;
		border-radius: 16px;
		padding: 56px 51px;
		margin-bottom: 76px;

		>div {
			margin-bottom: 40px;
			margin-left: 30px;
			font-weight: bold;
			font-family: SourceHanSansCN-Bold, sans-serif;
		}

		.tit {
			font-family: SourceHanSansCN-Bold, sans-serif;
			font-size: 32px;
			font-weight: bold;
			color: #97233F;
		}

		ol {
			margin-left: 25px;
			font-weight: bold;
			font-family: SourceHanSansCN-Bold, sans-serif;
		}
	}

	.title {
		font-family: Montserrat-Bold, sans-serif;
		font-size: 32px;
		font-weight: bold;
		padding: 0 111px;
		color: #FFF;
	}

	img {
		width: 100%;
		vertical-align: middle;
	}

	/deep/ .swiper-pagination-bullet-active {
		background-color: #97233F;
	}

	video {
		height: 911px;
		width: 100%;
	}
</style>